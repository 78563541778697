import React from 'react';
import ReactDOM from 'react-dom/client';
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import baseStore from "./redux/store";
import 'react-credit-cards-2/es/styles-compiled.css';
import './style/index.scss';
import Base from "./components/base/Base";
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<Index/>);

function Index() {
    const {store, persistor} = baseStore();

    return (
            <Provider store={store}>
                <PersistGate loading={false} persistor={persistor}>
                        <Base/>
                </PersistGate>
            </Provider>
    )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
